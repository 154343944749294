<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <v-system-bar
            height="47"
            :color="
              form.status == 'Active' || empty(form.status)
                ? '#aeddff'
                : '#ff5252'
            "
          >
            <v-icon @click="close">mdi-close</v-icon>
            <h3
              :class="form.status == 'Active' ? 'black--text' : 'white--text'"
            >
              {{ title }}
            </h3>
            <span
              class="ml-3 mt-1 white--text blink"
              v-if="form.status == 'Disabled'"
            >
              RECORD IS DISABLED
            </span>
            <v-spacer></v-spacer>
            <v-btn
              title
              color="primary"
              v-if="allownew && view != 'new'"
              @click="newForm"
              elevation="0"
              class="mr-2"
            >
              <v-icon color="white">mdi-plus</v-icon>
              New
            </v-btn>
            <v-btn
              title
              color="warning"
              v-if="allowEdit && !empty(form.id) && view == 'view'"
              @click="view = 'edit'"
              elevation="0"
            >
              <v-icon color="white">mdi-pencil</v-icon>
              Edit
            </v-btn>
            <v-btn
              title
              color="warning"
              v-if="allowEdit && view != 'view'"
              @click="cancelForm"
              elevation="0"
            >
              <v-icon color="white">mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <v-btn
              title
              color="success"
              class="ml-2"
              v-if="
                (allowEdit || allownew) && (view == 'new' || view == 'edit')
              "
              @click="saveForm"
              elevation="0"
            >
              <v-icon color="white">mdi-content-save</v-icon>
              Save
            </v-btn>
            <v-btn
              title
              color="error"
              class="ml-2"
              v-if="
                allowDisable &&
                !empty(form.id) &&
                form.status == 'Active' &&
                view == 'view'
              "
              @click="disableRecord(false)"
              elevation="0"
            >
              <v-icon color="white">mdi-close-circle</v-icon>
              Disable
            </v-btn>
            <v-btn
              title
              color="success"
              class="ml-2"
              v-if="
                allowDisable &&
                !empty(form.id) &&
                form.status == 'Disabled' &&
                view == 'view'
              "
              @click="disableRecord(true)"
              elevation="0"
            >
              <v-icon color="white">mdi-close-circle</v-icon>
              Enable
            </v-btn>
          </v-system-bar>
          <v-divider />
          <v-card-text>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
              class="demo-ruleForm"
            >
              <div class="row">
                <div class="col-lg-12 col-sm-12 text-center">
                  <el-form-item label="" prop="logo_path">
                    <el-upload
                      v-if="
                        empty(form.logo_path) &&
                        (view == 'new' || view == 'edit')
                      "
                      class="upload-demo"
                      drag
                      :action="url + end_point + 'upload-photo'"
                      :headers="{
                        Authorization: 'Bearer ' + token,
                      }"
                      :show-file-list="false"
                      :on-success="handleUploadSuccess"
                      :before-upload="beforeUpload"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or click to upload
                      </div>
                      <div class="el-upload__tip" slot="tip">
                        jpg/png files with a size less than 500kb
                      </div>
                      <div v-if="!empty(upload_error)" class="red__text">
                        {{ upload_error }}
                      </div>
                    </el-upload>
                    <template v-if="!empty(form.logo_path) || view == 'view'">
                      <img
                        width="150px"
                        class="el-upload-list__item-thumbnail"
                        :src="imUrl + 'events/' + form.logo_path"
                        alt=""
                      />
                      <div style="float: right; width: 300px">
                        <img
                          v-if="
                            view == 'view' &&
                            !empty(form.codes) &&
                            form.codes.length > 0
                          "
                          width="150px"
                          class="el-upload-list__item-thumbnail"
                          :src="
                            imUrl + 'event_codes/' + form.codes[0].id + '.png'
                          "
                          alt=""
                        />
                        <el-input
                          placeholder="Click to Copy"
                          v-if="
                            view == 'view' &&
                            !empty(form.codes) &&
                            form.codes.length > 0
                          "
                          size="mini"
                          :disabled="true"
                          style="width: 100%"
                          v-model="form.codes[0].code"
                          id="code"
                          ref="code"
                          name="code"
                          class="input-with-select mt-2"
                        >
                          <el-button
                            slot="append"
                            size="mini"
                            @click="copyUrl"
                            icon="el-icon-document-copy"
                          ></el-button>
                        </el-input>
                      </div>
                      <div class="mt-3">
                        <span
                          @click="handlePictureCardPreview(form.logo_path)"
                          class="mr-2"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                          v-if="view == 'new' || view == 'edit'"
                          @click="handleRemove(form.logo_path)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </div>
                      <el-dialog :visible.sync="previewVisible">
                        <img
                          width="100%"
                          :src="imUrl + 'events/' + previewImageUrl"
                          alt=""
                        />
                      </el-dialog>
                    </template>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="English Name" prop="name">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.name"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item
                    label="Dhivehi Name"
                    prop="dhivehi_name"
                    style="text-align: right"
                  >
                    <span slot="label" class="thaana">ދިވެހި ނަން</span>
                    <dhivehi-field
                      v-model="form.dhivehi_name"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Slug (Short URL)" prop="slug">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.slug"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Custom Domain" prop="custom_domain">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.custom_domain"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Event Type" prop="event_types_id">
                    <select-list
                      v-model="form.event_types_id"
                      editor="events/event-types"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item
                    label="Event Location"
                    prop="event_locations_id"
                  >
                    <select-list
                      v-model="form.event_locations_id"
                      editor="events/event-locations"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Free Event" prop="free_event">
                    <el-checkbox
                      v-model="form.free_event"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item
                    label="Single Day Event"
                    prop="single_day_event"
                  >
                    <el-checkbox
                      v-model="form.single_day_event"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item
                    :label="form.single_day_event ? 'Event Date' : 'From Date'"
                    prop="from_date"
                  >
                    <el-date-picker
                      v-model="form.from_date"
                      format="yyyy-MM-dd"
                      size="mini"
                      type="date"
                      placeholder="Pick a day"
                      :picker-options="futurePickerOptions"
                    ></el-date-picker>
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12" v-if="!form.single_day_event">
                  <el-form-item label="To Date" prop="to_date">
                    <el-date-picker
                      v-model="form.to_date"
                      format="yyyy-MM-dd"
                      size="mini"
                      type="date"
                      placeholder="Pick a day"
                      :picker-options="futurePickerOptions"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-sm-12" v-if="!form.single_day_event">
                  <el-form-item
                    label="Use Different Times"
                    prop="different_times"
                  >
                    <el-checkbox
                      v-model="form.different_times"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12" v-if="!form.free_event">
                  <el-form-item
                    label="Allow Multiple Fares"
                    prop="use_different_fares"
                  >
                    <el-checkbox
                      v-model="form.use_different_fares"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item
                    label="Allow Personalized Codes"
                    prop="allow_personalized_codes"
                  >
                    <el-checkbox
                      v-model="form.allow_personalized_codes"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item
                    label="Use Different Codes"
                    prop="different_codes_for_days"
                  >
                    <el-checkbox
                      v-model="form.different_codes_for_days"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Start Time" prop="start_time">
                    <el-time-picker
                      v-model="form.start_time"
                      size="mini"
                      placeholder="Pick a time"
                      format="HH:mm"
                    ></el-time-picker>
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="End Time" prop="end_time">
                    <el-time-picker
                      v-model="form.end_time"
                      size="mini"
                      placeholder="Pick a time"
                      format="HH:mm"
                    ></el-time-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Intro Text" prop="intro_text">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.intro_text"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Header Text" prop="custom_header">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.custom_header"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Footer Text" prop="custom_footer">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.custom_footer"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="Booking Confirmation Message"
                    prop="booking_confirmed_message"
                  >
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.booking_confirmed_message"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Mail Server Host" prop="mail_server">
                    <el-input
                      v-model="form.mail_server"
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item
                    label="Mail Server Port"
                    prop="mail_server_port"
                  >
                    <el-input-number
                      v-model="form.mail_server_port"
                      :controls="false"
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="User Name" prop="use_name">
                    <el-input
                      v-model="form.user_name"
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Password" prop="password">
                    <el-input
                      v-model="form.password"
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Sender Name" prop="sender_name">
                    <el-input
                      v-model="form.sender_name"
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Sender Email" prop="sender_email">
                    <el-input
                      v-model="form.sender_email"
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <el-form-item label="SMS Gateway" prop="sms_gateway">
                    <el-input
                      v-model="form.sms_gateway"
                      type="password"
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="RSVP Registration SMS"
                    prop="register_sms"
                  >
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.register_sms"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="RSVP Re Registration SMS"
                    prop="re_register_sms"
                  >
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.re_register_sms"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Event Visited SMS" prop="event_sms">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.event_sms"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row" v-if="view == 'ttt'">
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                      <el-button size="mini" type="default" @click="addFare">
                        Add New Fare
                      </el-button>
                    </div>
                  </div>
                  <el-tabs v-model="activeTab">
                    <el-tab-pane
                      label="Bookings"
                      name="bookings"
                      v-if="view == 'view'"
                    >
                      <el-table :data="form.bookings" style="width: 100%">
                        <el-table-column
                          fixed
                          prop="name"
                          label="Name"
                        ></el-table-column>
                        <el-table-column prop="email" label="Email" />
                        <el-table-column prop="mobile_no" label="Mobile_no" />
                        <el-table-column
                          prop="created_at"
                          label="Registered On"
                        />
                        <el-table-column label="..." width="40">
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="small"
                              @click="viewBooking(scope.row)"
                            >
                              View
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane
                      label="Codes"
                      name="codes"
                      v-if="this.form.fares.length > 0"
                    >
                      <el-table :data="form.codes" style="width: 100%">
                        <el-table-column
                          fixed
                          prop="code"
                          label="Code"
                        ></el-table-column>
                        <el-table-column
                          prop="name"
                          label="Name"
                        ></el-table-column>
                        <el-table-column prop="email" label="Email" />
                        <el-table-column prop="mobile_no" label="Mobile_no" />
                        <el-table-column
                          prop="created_at"
                          label="Registered On"
                        />
                        <el-table-column label="..." width="40">
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="small"
                              @click="viewCode(scope.row)"
                            >
                              View
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane
                      label="Fares"
                      name="fares"
                      v-if="form.dates.length > 0"
                    >
                      <el-table :data="form.fares" style="width: 100%">
                        <el-table-column
                          fixed
                          prop="fare_name"
                          label="Fare Name"
                        ></el-table-column>
                        <el-table-column
                          prop="rate"
                          label="Rate"
                        ></el-table-column>
                        <el-table-column prop="sort_order" label="Sort Order" />
                        <el-table-column label="..." width="40">
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="small"
                              @click="viewFare(scope.row)"
                            >
                              View
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Dates" name="dates">
                      <el-table :data="form.dates" style="width: 100%">
                        <el-table-column
                          fixed
                          prop="date"
                          label="Date"
                        ></el-table-column>
                        <el-table-column prop="start_time" label="Start Time" />
                        <el-table-column prop="end_time" label="End Time" />
                        <el-table-column
                          prop="max_capacity"
                          label="Maximum Capacity"
                        />
                        <el-table-column label="..." width="40">
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="small"
                              @click="viewDate(scope.row)"
                            >
                              View
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <el-form-item label="Event Profile" prop="event_profile">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="10"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.event_profile"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </v-card-text>
          <!--<v-divider />-->
        </v-card>
        <v-overlay :value="loading" :opacity="0.7">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <event-fares
          :show="showEventFareScreen"
          :data="form"
          :perm="perm"
          :popup="true"
          @close="showEventFareScreen = false"
        />
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Form,
  FormItem,
  Input,
  InputNumber,
  Select,
  Option,
  Upload,
  Dialog,
  Checkbox,
  DatePicker,
  TimePicker,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Message,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Option);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Checkbox);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Table);
Vue.use(TableColumn);
Vue.prototype.$message = Message;
import Swal from "sweetalert2";
import DhivehiField from "../../custom/DhivehiField.vue";
import SelectList from "@/components/custom/SelectList.vue";
import eventFares from "./eventfares.vue";
import { baseUrl, imageUrl } from "../../../utils/repo";
export default {
  created() {},
  watch: {
    show: function () {
      this.showForm = this.show;
      if (!this.empty(this.perm)) this.permissions = this.perm;
      if (this.showForm) {
        if (this.empty(this.data)) {
          this.newForm();
        } else {
          this.view = "view";
        }
        this.activeTab = this.view == "view" ? "bookings" : "codes";
      }
    },
    data: function () {
      if (!this.empty(this.data)) {
        this.getRecord();
      }
    },
    perm: function () {
      this.permissions = this.perm;
    },
    view: function () {
      this.activeTab = this.view == "view" ? "bookings" : "codes";
    },
  },
  props: {
    data: null,
    show: {
      type: Boolean,
      default: false,
    },
    perm: null,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {
    DhivehiField,
    SelectList,
    eventFares,
  },
  data() {
    return {
      end_point: "events/events/",
      activeTab: this.view == "view" ? "bookings" : "code",
      title: "Events",
      upload_error: "",
      previewImageUrl: "",
      previewVisible: false,
      showEventDateScreen: false,
      showEventFareScreen: false,
      file: null,
      currentPage: 1,
      perPage: 25,
      total: 0,
      form: {
        id: "",
        name: "",
        slug: "",
        dhivehi_name: "",
        logo_path: null,
        logo_byte: null,
        event_types_id: null,
        event_locations_id: null,
        single_day_event: null,
        from_date: null,
        to_date: null,
        start_time: null,
        end_time: null,
        different_times: null,
        use_different_fares: null,
        require_location: null,
        require_event_url: null,
        allowed_personalized_codes: null,
        different_codes_for_days: null,
        free_event: null,
        use_custom_domain: null,
        custom_domain: null,
        custom_header: null,
        custom_footer: null,
        booking_confirmed_message: null,
        intro_text: null,
        event_profile: null,
        mail_server: null,
        mail_server_port: null,
        user_name: null,
        password: null,
        sender_email: null,
        sender_name: null,
        sms_gateway: null,
        register_sms: null,
        re_register_sms: null,
        event_sms: null,
        bookings: [],
        codes: [{ id: 0 }],
        dates: [],
        fares: [],
        users_id: "",
        status_id: "",
        newd_at: "",
        updated_at: "",
      },
      view: "view",
      rules: {
        name: [
          { required: true, message: "Please enter Name", trigger: "blur" },
        ],
        slug: [
          {
            required: true,
            message: "Please enter Slug (Short URL)",
            trigger: "blur",
          },
        ],
        event_locations_id: [
          {
            required: true,
            message: "Please select Event Location",
            trigger: "blur",
          },
        ],
        event_types_id: [
          {
            required: true,
            message: "Please select Event Type",
            trigger: "blur",
          },
        ],
        from_date: [
          {
            required: true,
            message: "Please select from Date",
            trigger: "blur",
          },
        ],
        to_date: [
          {
            required: true,
            message: "Please select to Date",
            trigger: "blur",
          },
        ],
        start_time: [
          {
            required: true,
            message: "Please select to Start Time",
            trigger: "blur",
          },
        ],
        end_time: [
          {
            required: true,
            message: "Please select to End Time",
            trigger: "blur",
          },
        ],
        intro_text: [
          {
            required: true,
            message: "Please enter Intro Text",
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      loading: false,
      showForm: false,
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create";
      if (!this.empty(this.form.id)) api = "update";
      this.form.from_date = this.formatDate(this.form.from_date);
      this.form.to_date = this.formatDate(this.form.to_date);
      this.form.start_time = this.formatTime(this.form.start_time);
      this.form.end_time = this.formatTime(this.form.end_time);
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.view = "view";
          this.showInfo("Record Saved successfully");
          this.$emit("updated");
          if (this.popup) this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    disableRecord(enable) {
      var title = "Do you want Enable the record?";
      var text = "Once enabled users can view the record";
      var button = "Yes, enable it!";
      if (!enable) {
        title = "Do you want to disabled the record?";
        text = "Once disabled users wont be able to view the records";
        button = "Yes, disable it!";
      }
      Swal.fire({
        title: title,
        text: text,
        type: "question",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#5daf34",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonText: button,
      }).then((result) => {
        if (result.value) {
          this.change_status(enable);
        }
      });
    },
    change_status(enable) {
      var api = "enable";
      if (!enable) api = "disable";
      this.loading = true;
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      this.form = {
        id: "",
        name: "",
        slug: "",
        dhivehi_name: "",
        logo_path: null,
        logo_byte: null,
        event_types_id: null,
        event_locations_id: null,
        single_day_event: null,
        from_date: null,
        to_date: null,
        start_time: null,
        end_time: null,
        different_times: null,
        use_different_fares: null,
        require_location: null,
        require_event_url: null,
        allowed_personalized_codes: null,
        different_codes_for_days: null,
        free_event: null,
        use_custom_domain: null,
        custom_domain: null,
        custom_header: null,
        custom_footer: null,
        booking_confirmed_message: null,
        intro_text: null,
        event_profile: null,
        mail_server: null,
        mail_server_port: null,
        user_name: null,
        password: null,
        sender_email: null,
        sender_name: null,
        sms_gateway: null,
        register_sms: null,
        re_register_sms: null,
        event_sms: null,
        bookings: [],
        codes: [],
        dates: [],
        fares: [],
        users_id: "",
        status_id: "",
        newd_at: "",
        updated_at: "",
      };
      this.view = "new";
    },
    getRecord() {
      let f = {
        id: this.data.id,
      };
      this.loading = true;
      this.api(this.end_point + "record", f)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async copyUrl() {
      await navigator.clipboard.writeText(this.form.codes[0].code);
      this.$message({
        message: "Copied to clipboard",
        type: "success",
      });
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      if (this.popup) this.close();
    },
    handleSizeChange(val) {
      this.perPage = val;
      this.getBookings();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getBookings();
    },
    getBookings() {
      let f = {
        id: this.data.id,
        filter: "",
        page: this.currentPage,
        per_page: this.perPage,
        sort: "",
      };
      this.loading = true;
      this.api(this.end_point + "get-bookings", f)
        .then((response) => {
          this.loading = false;
          this.form.bookings = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleUploadSuccess(res) {
      this.form.logo_path = res.path; //URL.createObjectURL(file.raw);
      this.$forceUpdate();
    },
    beforeUpload(file) {
      this.upload_error = "";
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.upload_error = "Picture must be JPG/JPG format!";
      }
      if (!isLt2M) {
        this.upload_error = "Picture size can not exceed 2MB!";
      }
      return isJPG && isLt2M;
    },
    handleRemove() {
      this.form.logo_path = "";
    },
    handlePreview(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.previewImageUrl = file;
      this.previewVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    //region Booking
    viewBooking(row) {
      console.log(row);
    },
    //endregion Booking
    //region Codes
    viewCode(row) {
      console.log(row);
    },
    //endregion Codes
    //region Fares
    viewFare(row) {
      console.log(row);
    },
    addFare() {
      this.showEventFareScreen = true;
    },
    //endregion Fares
    //region Dates
    viewDate(row) {
      console.log(row);
    },
    addDate() {
      this.showEventDateScreen = true;
    },
    prepareEventDates() {
      if (this.empty(this.form.from_date) || !this.empty(this.form.id)) return;
      if (this.form.single_day_event) {
        let f = {
          events_id: "",
          date: this.formatDate(this.form.from_date),
          start_time: this.start_time,
          end_time: this.end_time,
          max_capacity: this.max_capacity,
          custom_header: this.custom_header,
          custom_footer: this.custom_footer,
          booking_confirmed_message: this.booking_confirmed_message,
        };
        this.form.dates = [];
        this.form.dates.push(f);
      }
    },
    //endregion Dates
  },
  computed: {
    allownew() {
      return (
        this.permissions.filter((e) => e.permission == "Create").length > 0
      );
    },
    allowEdit() {
      return this.permissions.filter((e) => e.permission == "Edit").length > 0;
    },
    allowDisable() {
      return (
        this.permissions.filter((e) => e.permission == "Disable").length > 0
      );
    },
    url() {
      return `${baseUrl}`;
    },
    imUrl() {
      return `${imageUrl}`;
    },
  },
  name: "EventsForm",
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 150px !important;
}
</style>
